<template>
  <div>
    <v-container fluid>
      <h1 class="titulo">CONTAS E CARTEIRAS</h1>

      <v-skeleton-loader type="table" :loading="skLoader" :transition-group="'fade-transition'">
        <v-row>
          <v-col md="3">
            <button
              class="addAccountButton"
              @click="openModalCadastro">
              + nova conta
            </button>
          </v-col>

          <v-col v-for="(account, index) in accounts" :key="index" md="3">
            <button
              :class="account.balance < 0 ? 'addAccountButton addAccountButton-negativeBalance' : 'addAccountButton addAccountButton-positiveBalance'"
              @click="showRecords(account)">
              <div class="addAccountButton-actionButton">
                <OrganizzeActionButton
                  :canEdit="true"
                  :canDelete="true"
                  @onEdit="editModalCadastro({ account, tab: 'tab-2' })"
                  @onDelete="openModalExcluir(account)">
                  <v-list-item
                    slot="listExtension"
                    v-for="(itemMenu, index) in menuOptions"
                    :key="index">
                    <v-list-item-content>
                      <v-btn
                        small
                        outlined
                        :color="variables.colorPrimary"
                        @click="selectMenuItem(itemMenu.id, account)">
                        {{itemMenu.name}}
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </OrganizzeActionButton>
              </div>

              <v-list-item-avatar>
                <img :src="(account.Institutions || {}).icon
                  || 'https://e7.pngegg.com/pngimages/456/851/png-clipart-wallet-computer-icons-wallet-brown-leather-thumbnail.png'"/>
              </v-list-item-avatar>

              <h3 class="accountTypeButton-icon" large>{{account.name + ' (' + accountTypes[account.type] + ')'}}</h3>
              <h1 class="accountTypeButton-icon" large >{{account.balance | currency}}</h1>
              <!-- <h4 class="accountTypeButton-icon" style="margin-top: 16px;" large >Saldo Inicial: {{account.initialBalance | currency}}</h4> -->
            </button>
          </v-col>
        </v-row>
      </v-skeleton-loader>
    </v-container>
    <ModalCadastro/>
    <ModalExcluir/>
  </div>
</template>

<script>
import Events from '@/core/service/events'
import { mapActions, mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'
import { accountTypesEnum } from '@/utils/tempUtils'
import { incRequestCounter, REQUEST_KEY_ALL } from '@/utils/miscellaneous'

const namespaceStore = 'organizzeFinanceiroContas'

export default {
  name: 'OrganizzeFinanceiroContas',

  components: {
    ModalCadastro: () => import('./components/modalCadastro'),
    ModalExcluir: () => import('./components/modalExcluir'),
    OrganizzeActionButton: () => import('@/views/components/organizze/actionButton')
  },

  computed: {
    ...mapGetters(namespaceStore, ['accounts']),
    ...mapGetters('roles', ['permiteAcao']),
    variables: () => variables
  },

  data: () => ({
    menuOptions: [{ id: 'showRecords', name: 'Ver lancaçamentos' }],
    accountTypes: null,
    skLoader: true
  }),

  created () {
    const self = this
    self.accountTypes = accountTypesEnum()
  },

  mounted () {
    const self = this
    self.buscarContas()

    Events.$on('index::getAccounts', () => {
      self.buscarContas()
    })
  },

  methods: {
    ...mapActions(namespaceStore, ['getAccounts']),

    openModalCadastro () { Events.$emit('modalCadastro::open') },
    editModalCadastro (data) { Events.$emit('modalCadastro::edit', data) },
    openModalExcluir (data) { Events.$emit('modalExcluir::open', data) },

    selectMenuItem (idMenu, account) {
      const self = this
      const observer = {
        showRecords: (() => self.showRecords(account))
      }
      observer[idMenu]()
    },

    buscarContas () {
      const self = this
      self.skLoader = incRequestCounter(REQUEST_KEY_ALL, 1)
      self.getAccounts().then(() => {}).catch(e => { console.error(e) }).finally(() => { self.skLoader = incRequestCounter(REQUEST_KEY_ALL, -1) })
    },

    showRecords (account) {
      const self = this
      const initFilterParams = {
        organizzeFilter: [{ filterKey: 'Records:accountId_=', item: { accountId: account.id } }],
        otherFilter: { 'Records:accountId_=': account.id }
      }
      self.$router.push({ name: 'lancamentos', params: { initFilterParams }})
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .addAccountButton {
    width: inherit;
    min-height: 210px;
    color: $colorAccent;
    border: 2px dashed $colorAccent;
    position: relative;

    &-actionButton {
      position: absolute;
      top: 16px;
      right: 16px;
    }

    &-positiveBalance {
      color: green;
      border: 2px dashed green;
    }

    &-negativeBalance {
      color: red;
      border: 2px dashed red;
    }

    &-childrens { color: $colorAccent; }

    &:hover {
      color: $colorPrimary !important;
      border-color: $colorPrimary !important;

      .addAccountButton-childrens { color: $colorPrimary !important; }
    }
  }
</style>